@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Avenir;
  src: url("./assets/fonts/Avenir.ttc");
}
@font-face {
  font-family: contl;
  src: url("./assets/fonts/contl.ttf");
}
@font-face {
  font-family: DB-Heavent;
  src: url("./assets/fonts/DB\ Heavent\ v3.2.1.ttf");
}
@font-face {
  font-family: Lato_Regular;
  src: url("./assets/fonts/Lato-Regular.ttf");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
