html,
body {
  scroll-behavior: smooth !important;
}
#homepage {
  background-image: url("./assets/images/AW_KBANK_BG-Mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
#agenda,
#speakers {
  background-image: url("./assets/images/AW_KBANK_2_BG_Agenda.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#startup {
  background-image: url("./assets/images/AW_KBANK_BG_Organized.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.exhibitors {
  display: grid;
  grid-template-areas:
    "icon1 icon2 "
    "icon3  icon4"
    "nav  foot";
  /* grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr; */
}
.exhibitors > .icon1 {
  grid-area: icon1;
}
.exhibitors .icon2 {
  grid-area: icon2;
}
.exhibitors .icon3 {
  grid-area: icon3;
}
.exhibitors .icon4 {
  grid-area: icon4;
}
.exhibitors .icon5 {
  grid-area: icon5;
}
.exhibitors .icon6 {
  grid-area: icon6;
}
.exhibitors .icon7 {
  grid-area: icon7;
}
.exhibitors .icon8 {
  grid-area: icon8;
}
.exhibitors .icon9 {
  grid-area: icon9;
}
@media screen and (min-width: 768px) {
  #homepage {
    background-image: url("./assets/images/AW_KBANK_BG.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  #startup {
    background-image: url("./assets/images/AW_KBANK_BG_Organized.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
